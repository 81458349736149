import styled from 'styled-components';
import React from 'react';

const ZoomButtons = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 30px;
  right: 30px;
  height: 70px;
  width: 35px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  .btn-zoom {
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    cursor: pointer;
    flex: 1;
    &:first-child {
      border-bottom: 1px solid #eee;
    }
    &:hover {
      background-color: #000;
    }
  }
  @media (max-width: 500px) {
    bottom: 20px;
    right: 15px;
    z-index: 3000;
  }
  overflow: hidden;
`;

export default ({ handleZoom }) => {
  return (
    <ZoomButtons>
      <div className="btn-zoom" onClick={() => handleZoom('in')}>
        <i className="fa-regular fa-magnifying-glass-plus" />
      </div>
      <div className="btn-zoom" onClick={() => handleZoom('out')}>
        <i className="fa-regular fa-magnifying-glass-minus" />
      </div>
    </ZoomButtons>
  );
};
