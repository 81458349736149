import React from 'react';
import styled from 'styled-components';

import Button from 'components/button';
import Icon from 'components/icon';

import SearchInput from './search-input';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 0 0;
`;

const BackButton = styled(Button)`
  padding: 0 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ({ onChange, onClose, orgId, segmentId }) => {
  return (
    <Wrapper>
      <SearchWrapper>
        <BackButton onClick={onClose}>
          <Icon name="fa-solid fa-arrow-left" />
        </BackButton>
        <SearchInput segmentId={segmentId} orgId={orgId} onChange={onChange} autoFocus />
      </SearchWrapper>
    </Wrapper>
  );
};
