import React from 'react';
import styled from 'styled-components';

import Button from 'components/button';
import Icon from 'components/icon';

import useListingsNavigate from '../../utils/useListingsNavigate';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 400;
  width: 420px;
  height: 120px;
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.3);
  }
  .body {
    flex: 1;
    padding: 0 10px;
    line-height: 1.4;
    .title {
      font-weight: bold;
    }
    .address {
      font-size: 13px;
    }
  }
  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  @media (max-width: 768px) {
    bottom: 80px;
    width: 90%;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.3);
  }
`;

const Photo = styled.div`
  width: 120px;
  height: 120px;
  background-color: #eee;
  background-image: url(${({ img }) => img}), url('/assets/location-placeholder2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export default ({ location: { displayInfo = {}, _id } = {}, orgId, onClose }) => {
  const navigate = useListingsNavigate();

  return (
    <Wrapper onClick={() => navigate(`${orgId}/${_id}`)}>
      <Photo img={displayInfo.heroImage} />
      <div className="body">
        <div className="title">{displayInfo.title}</div>
        <div className="address">{displayInfo.address}</div>
        <br />
        <div className="amenities">
          {displayInfo.bedCount && (
            <>
              <strong>{displayInfo.bedCount}</strong> bd {'| '}
            </>
          )}
          {displayInfo.bathCount && (
            <>
              <strong>{displayInfo.bathCount}</strong> ba {'| '}
            </>
          )}
          {displayInfo.squareFeet && (
            <>
              <strong>{displayInfo.squareFeet}</strong> sqft
            </>
          )}
        </div>
      </div>
      <Button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClose?.();
        }}>
        <Icon name="fa-solid fa-xmark" />
      </Button>
    </Wrapper>
  );
};
