import React from 'react';
import styled from 'styled-components';
import PlaceAutocomplete from 'components/places-autocomplete';
import { LOAD_LISTINGS } from 'dal/context';

const Wrapper = styled.div`
  position: relative;
  height: 45px;
  flex: 1;
  max-width: 600px;
`;

export default ({ onChange, autoFocus, orgId, segmentId }) => {
  const fetchCustomResults = async (query) => {
    const results = await LOAD_LISTINGS({
      orgId: orgId,
      segmentId,
      filters: {
        search: query,
        page: 0,
        size: 5
      }
    });
    return results?.data?.map((l) => ({
      ...l,
      label: l.address?.formattedAddress || l.postingTitle || l.marketingName || l.name,
      type: 'custom',
      icon: 'fa-regular fa-house',
      value: l._id,
      geopoint: l?.address?.geopoint
    }));
  };

  return (
    <Wrapper>
      <PlaceAutocomplete
        autoFocus={autoFocus}
        loadOptions={fetchCustomResults}
        onPlaceSelect={onChange}
        disablePlaces
      />
    </Wrapper>
  );
};
