import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import MobileHeader from 'components/mobile-header';

import { useSearch } from './hooks';

import Property from '../property';
import LoadingScreen from './loading-screen';
import List from './list';
import Map from './map';
import Filters from './filters';
import FloatingToolbar from './floating-toolbar';
import { ModalFilters } from './filters/filters-popover';
import SearchMobile from './filters/search-mobile';
import useListingsNavigate from '../utils/useListingsNavigate';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  *:not(i) {
    box-sizing: border-box;
  }
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const MapWrapper = styled.div`
  display: flex;
  padding: 10px;
  padding-right: 5px;
  flex: 1;
`;

export default () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [tab, setTab] = React.useState('list');
  const [showFilters, setShowFilters] = React.useState();
  const [showSearchMobile, setShowSearchMobile] = React.useState();
  const [mapCenter, setMapCenter] = React.useState({ center: null, zoom: null });
  const [activeMarkerId, setActiveMarkerId] = React.useState();

  const params = useParams();
  const orgId = params.orgId;
  const segmentId = params.segmentId;
  const [id, floorplanId, unitId] = (params['*'] || '').split('/');
  const navigate = useListingsNavigate();

  const {
    setSort,
    sort,
    locations,
    loading,
    hasFetched,
    orgFetched,
    locationCount,
    setPageNumber,
    visibleMapBounds,
    pageNumber,
    pageLength,
    filters,
    setFilters,
    orgInfo,
    setMapReady,
    onCameraChanged
  } = useSearch({ orgId, segmentId, mapReady: isMobile });

  const handleToolbarClick = (action) => {
    if (action === 'search') {
      setShowSearchMobile(true);
    }
    if (['map', 'list'].includes(action)) {
      setTab(action);
    }
    if (action === 'filters') {
      setShowFilters(true);
    }
  };

  const setLocation = (location) => {
    // don't recenter if we dont have a geo point
    if (!location?.geopoint) return;

    // only recenter if this is new
    if (
      mapCenter?.center?.lat === location.geopoint[1] &&
      mapCenter?.center?.lng === location.geopoint[0]
    ) {
      return;
    }

    setMapCenter({ zoom: 10, center: { lat: location.geopoint[1], lng: location.geopoint[0] } });
  };

  React.useEffect(() => {
    document.title = `${orgInfo?.companyName ? `${orgInfo?.companyName} ` : ''}Available Rentals`;
  }, [orgInfo?.companyName]);

  return (
    <Wrapper>
      {!isMobile && (
        <>
          <Filters
            filters={filters}
            setFilters={setFilters}
            setLocation={setLocation}
            orgId={orgId}
            segmentId={segmentId}
            orgInfo={orgInfo}
          />
          <Body>
            {!orgFetched ? (
              <LoadingScreen />
            ) : (
              <>
                <MapWrapper>
                  <Map
                    setMapReady={setMapReady}
                    visibleMapBounds={visibleMapBounds}
                    orgInfo={orgInfo}
                    onCameraChanged={onCameraChanged}
                    locations={locations}
                    orgId={orgId}
                    loading={loading}
                    mapCenter={mapCenter}
                    setActiveMarkerId={setActiveMarkerId}
                  />
                </MapWrapper>
                <List
                  setSort={setSort}
                  sort={sort}
                  loading={loading}
                  locations={locations}
                  locationCount={locationCount}
                  orgId={orgId}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  pageLength={pageLength}
                  activeMarkerId={activeMarkerId}
                />
              </>
            )}
          </Body>
        </>
      )}
      {isMobile && (
        <>
          <MobileHeader logoUrl={orgInfo?.logo} />
          <Body>
            {!hasFetched ? (
              <LoadingScreen />
            ) : (
              <>
                {tab === 'list' && (
                  <List
                    setSort={setSort}
                    sort={sort}
                    loading={loading}
                    locations={locations}
                    locationCount={locationCount}
                    orgId={orgId}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    pageLength={pageLength}
                    activeMarkerId={activeMarkerId}
                  />
                )}
                {tab === 'map' && (
                  <Map
                    setMapReady={setMapReady}
                    visibleMapBounds={visibleMapBounds}
                    onCameraChanged={onCameraChanged}
                    locations={locations}
                    orgId={orgId}
                    loading={loading}
                    mapCenter={mapCenter}
                    setActiveMarkerId={setActiveMarkerId}
                  />
                )}
              </>
            )}
            <FloatingToolbar filters={filters} tab={tab} onClick={handleToolbarClick} />
            {showFilters && (
              <ModalFilters
                value={filters}
                onApply={(filters) => setFilters(filters)}
                setLocation={setLocation}
                orgId={orgId}
                orgInfo={orgInfo}
                onClose={() => setShowFilters(false)}
              />
            )}
          </Body>
        </>
      )}
      {showSearchMobile && (
        <SearchMobile
          segmentId={segmentId}
          orgId={orgId}
          onChange={(place) => {
            setLocation(place);
            setFilters((prev) => ({
              ...prev,
              autocomplete: place
            }));
            setShowSearchMobile(false);
          }}
          onClose={() => setShowSearchMobile(false)}
        />
      )}
      {id && (
        <Property
          locations={locations}
          locationId={id}
          floorplanId={floorplanId}
          unitId={unitId}
          orgId={orgId}
          onClose={() => navigate(`${orgId}/`)}
        />
      )}
    </Wrapper>
  );
};
