import React from 'react';
import styled from 'styled-components';
import Block from './block';
import { useDocumentContext } from '../../state';

const Paper = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  outline: 1px solid #ddd;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  padding: 0px;
  box-sizing: border-box;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  * {
    box-sizing: border-box;
  }
`;

const ImgPlaceholder = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

const getPageSize = ({ page }) => {
  const { scale = 1 } = page;
  const renderScale = 1.33;
  const width = Math.floor(page.width / scale);
  const height = Math.floor(page.height / scale);
  return {
    pageWidth: scale === 1 ? page.width : Math.floor(width * renderScale),
    pageHeight: scale === 1 ? page.height : Math.floor(height * renderScale)
  };
};

export const Page = ({
  page,
  pageUrl,
  blocks = [],
  updateBlock,
  users,
  focusedBlock,
  setFocusedBlock
}) => {
  const pageRef = React.useRef(null);
  const { pageWidth, pageHeight } = getPageSize({ page });

  return (
    <Paper
      ref={pageRef}
      width={pageWidth}
      height={pageHeight}
      id={`page_${page.uuid}`}
      className="pagePaper">
      {pageUrl && <ImgPlaceholder width={pageWidth} height={pageHeight} src={pageUrl} alt="" />}
      {blocks.map((block, i) => (
        <Block
          key={i}
          block={block}
          users={users}
          updateBlock={updateBlock}
          focusedBlock={focusedBlock}
          setFocusedBlock={setFocusedBlock}
          page={page}
        />
      ))}
    </Paper>
  );
};

export const useUpdateBlock = ({ page }) => {
  const [blocks, setBlocks] = React.useState([]);
  const { ui } = useDocumentContext();

  const updateBlock = (block) => {
    if (Array.isArray(block)) {
      const uuids = block.map((b) => b.uuid);
      setBlocks((blocks) =>
        blocks.map((b) => {
          if (uuids.includes(b.uuid) === false) return b;
          const update = block.find((block) => block.uuid === b.uuid);
          return { ...b, ...update };
        })
      );
      return;
    }
    setBlocks((blocks) =>
      blocks.map((b) => {
        if (b.uuid !== block.uuid) return b;
        return { ...b, ...block };
      })
    );
  };

  React.useEffect(() => {
    if (!page?.uuid) return;
    ui?.updatePage({ uuid: page.uuid, blocks });
  }, [blocks]);

  React.useEffect(() => {
    if (!page?.uuid) return;
    setBlocks(page.blocks || []);
  }, [page?.uuid, ui.pageToken]);

  return { blocks, updateBlock };
};

export default ({ page, zoom }) => {
  const { ui, doc } = useDocumentContext();
  const { blocks, updateBlock } = useUpdateBlock({ page });
  return (
    <Page
      page={page}
      pageUrl={page.url}
      blocks={blocks}
      users={doc.recipients}
      updateBlock={updateBlock}
      zoom={zoom}
      focusedBlock={ui.focusedBlock}
      setFocusedBlock={(uuid) => ui.updateUi({ focusedBlock: uuid })}
    />
  );
};
