import { useAPIData } from 'dal/useAPIData';
import { displayLocationInfo } from 'helpers/location';

import useNearbyLocations from './useNearbyLocations';
import useNearbySchools from './useNearbySchools';

export const useProperty = ({
  locationId,
  floorplanId,
  orgId,
  segmentId,
  fetchNearbyLocations
}) => {
  const [location, inProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_LISTING',
    !locationId ? undefined : { locationId, filters: { floorplans: true } },
    { reFetchWhen: [locationId], disableEmptyArgs: true }
  );

  const nearbyLocations = fetchNearbyLocations
    ? useNearbyLocations({ orgId, locationId, segmentId, address: location?.address })
    : [];
  const [floorplan, fInProgress, fDataFetched, fFailedFetch] = useAPIData(
    'context.LOAD_LISTING',
    !floorplanId ? undefined : { locationId: floorplanId, filters: { units: true } },
    { reFetchWhen: [floorplanId], disableEmptyArgs: true }
  );

  const nearbySchools = useNearbySchools({ locationId });

  return {
    failed: failedFetch,
    location: displayLocationInfo(location),
    loading: inProgress || !location,
    nearbyLocations: nearbyLocations?.locations,
    nearbySchools: nearbySchools?.schools,
    floorplan:
      floorplanId && floorplanId === floorplan?._id ? displayLocationInfo(floorplan) : undefined
  };
};
