import { useNavigate, useParams } from 'react-router-dom';

export default () => {
  const params = useParams();
  const navigate = useNavigate();
  const isFullPage = params?.['*']?.includes('/property/'); // kind of hacky

  let baseRoute = 'search';
  if (isFullPage) {
    baseRoute = 's';
  } else if (params.segmentId) {
    baseRoute = `segment/${params.segmentId}`;
  }

  return (route, ...vals) => {
    return navigate(`/${baseRoute}${route?.startsWith('/') ? route : `/${route}`}`, ...vals);
  };
};
