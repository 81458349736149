import React from 'react';
import styled from 'styled-components';
import { useDocumentContext } from '../state';

import HtmlPage from './html';
import PdfPage from './pdf';

const pageTypes = {
  html: HtmlPage,
  pdf: PdfPage
};

export default ({ ...props }) => {
  const { doc } = useDocumentContext();
  const Component = pageTypes[doc.builder];
  return <Component {...props} />;
};
