import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import Modal from 'components/modal';
import useListingsNavigate from '../utils/useListingsNavigate';

import Header from './header';
import Gallery from './gallery';
import Detail from './detail';
import { useProperty } from './hooks';
import LoadingScreen from './loading-screen';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  line-height: 1.3;
  *:not(i) {
    box-sizing: border-box;
  }
`;

const Body = styled.div`
  flex: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const FailedWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
`;

const LocationContent = ({
  loading,
  failed,
  selectedLocationId,
  selectedLocationType,
  selectedLocation,
  ...params
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (loading || failed || selectedLocation?._id !== selectedLocationId) return <></>;

  return (
    <>
      {!isMobile && <Gallery location={selectedLocation} />}
      <Detail
        locationType={selectedLocationType}
        hideFloorplans={['unit', 'floorplan'].includes(selectedLocationType)}
        hideUnits={['unit', 'location'].includes(selectedLocationType)}
        hideFees={!!selectedLocation?.floorplans?.length || !!selectedLocation?.units?.length}
        location={selectedLocation}
        {...params}
      />
    </>
  );
};

export const Property = ({
  orgId: providedOrgId,
  locationId: providedLocationId,
  onClose,
  floorplanId: initialFloorplan,
  unitId,
  isFullPage,
  locations = []
}) => {
  const params = useParams();
  const floorplanId = initialFloorplan || params.floorplanId;
  const orgId = providedOrgId || params.orgId;
  const locationId = providedLocationId || params.propertyId;
  const segmentId = params.segmentId;

  const { location, loading, nearbyLocations, nearbySchools, failed, floorplan } = useProperty({
    floorplanId,
    segmentId,
    locationId,
    orgId,
    fetchNearbyLocations: !isFullPage
  });
  const navigate = useListingsNavigate();

  const handleNextBack = (dir) => {
    const currentIndex = locations.findIndex((p) => p._id === locationId);
    const nextIndex = currentIndex < 0 ? 0 : dir === 'next' ? currentIndex + 1 : currentIndex - 1;
    const nextProp = locations[nextIndex] || locations[0];
    navigate(`${orgId}/${nextProp?._id}`);
  };

  const [
    selectedLocationType,
    selectedLocationId,
    selectedLocation,
    selectedLoading,
    selectedFailed
  ] = React.useMemo(() => {
    if (floorplanId && floorplan?._id === floorplanId) {
      return ['floorplan', floorplanId, floorplan, floorplanId !== floorplan?._id];
    }
    return ['building', locationId, location, loading, failed];
  }, [floorplan, location, locationId, floorplanId]);

  return (
    <Wrapper>
      <Header
        onClose={onClose}
        onNextBack={handleNextBack}
        location={location}
        floorplan={floorplan}
        orgId={orgId}
        showNavButtons={!floorplanId && locations.length > 0}
        isFullPage={isFullPage}
      />
      {!selectedFailed && selectedLoading && <LoadingScreen />}
      {selectedFailed && <FailedWrapper>Property is no longer available</FailedWrapper>}
      <Body>
        <LocationContent
          orgId={orgId}
          loading={selectedLoading}
          failed={selectedFailed}
          unitId={unitId}
          isFullPage={isFullPage}
          selectedLocationType={selectedLocationType}
          selectedLocation={selectedLocation}
          selectedLocationId={selectedLocationId}
          nearbyLocations={nearbyLocations}
          nearbySchools={nearbySchools}
        />
      </Body>
    </Wrapper>
  );
};

export default (props) => {
  return (
    <Modal
      width="1200px"
      height="98%"
      maxHeight="100%"
      radius={0}
      onClose={props.onClose}
      hideClose
      open>
      <Property {...props} />
    </Modal>
  );
};
