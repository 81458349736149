import React from 'react';
import styled from 'styled-components';
import Agreement from './agreement';
import Menu from './menu';
import Header from './header';
import Page from './page';
import StatusPane from './status-pane';
import LoadingScreen from 'components/full-screen-loader';
import FeedbackScreen from 'components/feedback-screen';
import Sign from './sign';
import { useTranslation } from 'react-i18next';
import { useFetchDocument } from './hooks';
import { DocumentProvider, useDocumentContext } from './state';
import SuccessScreen from './success';
import FinishSign from './finish-sign-footer';
import SignaturesList from './signatures-list';
import LinkExpired from './link-expired';
import CompleteAssistant from './complete-assistant';
import PagesViewer from './pages-viewer';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  font-size: 15px;
  * {
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const ExpiredMsg = styled.div`
  padding: 20px;
  border-top: 1px solid #ddd;
  text-align: center;
`;

const Esignature = () => {
  const { i18n } = useTranslation();
  const { ui, user, doc } = useDocumentContext();

  React.useEffect(() => {
    i18n.changeLanguage('en-US');
  }, []);

  return (
    <Wrapper>
      <Header />
      <Content>
        <Menu />
        {ui.showSignatures ? (
          <SignaturesList />
        ) : (
          <>{doc.builder === 'pdf' ? <PagesViewer /> : <Page />}</>
        )}
        <StatusPane />
      </Content>
      {ui.showAgreement && user.requireSignature && !doc.isExpired && <Agreement />}
      {!user.hasSigned && ui.allSigned && user.requireSignature && !doc.isExpired && <FinishSign />}
      {ui.showPad && user.requireSignature && <Sign />}
      {doc.isExpired && user.requireSignature && !user.hasSigned && (
        <ExpiredMsg>This document has expired.</ExpiredMsg>
      )}
      {ui.completeAssistant && <CompleteAssistant />}
    </Wrapper>
  );
};

const EsignaturePage = ({ documentState }) => {
  if (documentState.linkExpired) {
    return <LinkExpired />;
  }
  if (documentState.showSuccessScreen) {
    return <SuccessScreen onReturn={() => documentState.backToDocument()} />;
  }
  if (documentState.failed) {
    return <FeedbackScreen variant="error" text="Document Link is not valid." />;
  }
  if (documentState.loading) return <LoadingScreen text="LOADING DOCUMENT" />;
  return (
    <DocumentProvider documentState={documentState}>
      <Esignature />
    </DocumentProvider>
  );
};

export default () => {
  const documentState = useFetchDocument();
  return <EsignaturePage documentState={documentState} />;
};
