import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-family: sans-serif;
  overflow: hidden;
`;

export default ({ block }) => {
  return (
    <Wrapper>
      <div>{block.value}</div>
    </Wrapper>
  );
};
